
<template>
<div class="tgju-widgets-row">
    <div :class="'row tgju-widgets-row mr-1 mb-3 profile-post-widgets profile-mobile-view  '+ market_tab +''">
        <v-select v-model="discussion_active" @input="componentKey += 1,setMarketTab(discussion_active)" class="profile-mobile-view-select" :items="discussions" item-text="value"  item-value="text" label="انتخاب بازار" outlined></v-select>
        <TgjuPost message="کاربر مورد نظر در این کانال تاکنون دیدگاهی به ثبت نرسانده است" type="widget" auth="hide" :username="this.$route.params.username" sendbox="disabled" :subject="discussion_active" col="w100-w" :key="componentKey"></TgjuPost>
    </div>
</div>
</template>
 
<script>
// این ویو برای نمایش پروفایل کاربران دیگر است
import TgjuPost from '@/components/Mobile/TgjuPosts/Post.vue'


export default {
    name: 'ProfileOthersView',
    components: {
        TgjuPost,
    },
    data: function () {
        return {
            market_tab: 'tgju-discussion-18',
            componentKey: 0,
            discussion_active:'tgju-discussion-18',
            discussions: [
                { text: 'tgju-discussion-18', value: 'بازار طلا' },
                { text: 'tgju-discussion-19', value: 'بازار سکه' },
                { text: 'tgju-discussion-20', value: 'بازار ارز' },
                { text: 'tgju-discussion-global-2', value: 'بازار ارزهای دیجیتال' },
                { text: 'tgju-discussion-28', value: 'بازار بورس' },
                { text: 'tgju-discussion-36', value: 'بازار‌های کالایی' },
                { text: 'tgju-discussion-global-1', value: 'بازار‌های سهام' },
                { text: 'tgju-discussion-37', value: 'بازار نفت و انرژی' },
                { text: 'tgju-discussion-global-3', value: 'بازار مبادلات ارزی' },
                { text: 'tgju-discussion-35', value: 'بازار فلزات' },
            ],
        }
    },
    // متد کرییت قبل از متد مانت در ویو اجرا میشود
    created() {
        // در صورتی که منوی داشبورد سمت راست به دلایلی استایل گرفته بود نمایش داده نشودم
        // این استایل را از بین ببر و اجازه بده به نمایش در بیاید
        var item = document.getElementsByClassName('bar-menu-dashboard-box');
        item[0].style.display = "";

        // در صورتی که کاربر لاگین کرده بود
        if (this.$helpers.userSync()) {
            // یک سری اکشن ها رو کال کن
            this.$store.commit('setAccessToken', localStorage.getItem('access_token'));
            this.$store.dispatch('setUserData', { forced: true })
                .then(response => {
                    // ریدارکت کردن کاربر به پروفایل خود درصورت اینکه نام کاربری خود را درخواست کرده باشد در روت
                    if (this.$helpers.userAuth()) {
                        var username = this.$store.state.user ? this.$store.state.user.username : this.$helpers.json_pars(localStorage.getItem('user_data')).username;

                        if (username == this.$route.params.username) {
                            window.location = process.env.VUE_APP_URL + '/profile';
                        }
                    }
                });
            this.$store.dispatch('setDashboards', { forced: true });
        }
    },
    methods: {
        // این متد برای هندل کردن اکشن تغییر دسته بندی نظرات استفاده میشود
       onChange(event) {
            this.discussion = event.target.value;
            this.componentKey += 1
        },
        // این متد برای تغییر و هایلایت کردند بازار انتخاب شده مورد استفاده قرار میگیرد
        setMarketTab(elm) {
            this.market_tab = elm;
        },
    },
}
</script>
